import helpItem from './helpItem.vue';
export default {
  components: {
    helpItem
  },
  props: {
    helpData: {
      required: true,
      type: Array
    }
  }
};