var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "help_collapse_section"
  }, [_c('div', {
    staticClass: "help_collapse_icon"
  }, [_c('button', {
    staticClass: "__help_btn",
    on: {
      "click": function ($event) {
        _vm.helpItemData.isActive = !_vm.helpItemData.isActive;
      }
    }
  }, [!_vm.helpItemData.isActive ? _c('img', {
    attrs: {
      "src": require("../../assets/help_plus.svg"),
      "alt": ""
    }
  }) : _c('img', {
    attrs: {
      "width": "15px",
      "height": "15px",
      "src": require("../../assets//minus-line-svgrepo-com (1).svg"),
      "alt": ""
    }
  })])]), _c('div', {
    staticClass: "help_collapse_content"
  }, [_c('div', {
    staticClass: "help_collapse_header"
  }, [_c('h2', [_vm._v(" " + _vm._s(_vm.helpItemData.title) + " ")])]), _vm.helpItemData.isActive ? _c('div', {
    staticClass: "help_collapse_body"
  }, _vm._l(_vm.helpItemData.body, function (item, key) {
    return _c('p', {
      key: key
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0) : _vm._e(), _c('div', {
    staticClass: "__help_border_bottom"
  })])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };