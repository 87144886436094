var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "__help_component_wrapper"
  }, [_c('div', {
    staticClass: "__help_collapse_sections"
  }, _vm._l(_vm.helpData, function (item) {
    return _c('helpItem', {
      key: item.key,
      attrs: {
        "helpItemData": item
      }
    });
  }), 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };